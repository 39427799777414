<template>
  <div style="margin-top: 3rem">
    <div class="ytm-profile" v-if="!loading && profile">
      <div style="display: flex; margin: 0 2rem 1rem 2rem">
        <div style="width: 100%; display: flex; margin-bottom: 2rem">
          <div style="width: 14rem; margin-right: 5rem">
            <div class="ytm-avatar-wrapper">
              <img
                  :src="profile.avatar ? profile.avatar : 'https://t4.ftcdn.net/jpg/04/10/43/77/360_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg'"
                  alt="Image"
                  class="ytm-avatar"
              />
            </div>
          </div>
          <div style="display: table">
            <div style="display: table-cell; vertical-align: middle">
              <p style="font-size: 3rem; font-weight: 500; margin-bottom: 0.8rem">
                {{profile.firstName}} {{profile.middleName ? profile.middleName + ' ' : ''}}{{profile.surname}}
              </p>
              <p style="font-size: 1rem; color: #343a40">Репетитор</p>
            </div>
          </div>
        </div>
      </div>
      <div style="margin-bottom: 5rem">
        <p style="font-size: 1.5rem; font-weight: 500; margin-left: 1rem; margin-bottom: 1.25rem">
          О себе
        </p>
        <EditorContent :editor="brief" style="margin-bottom: 1rem"/>
        <EditorContent :editor="about"/>
      </div>
    </div>
    <div class="ytm-profile" v-else-if="error_msg" style="display: table; height: 100vh">
      <div style="display: table-cell; vertical-align: middle; text-align: center">
        <p style="font-size: 2rem; font-weight: 400;">
          {{error_msg}}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {SERVICE_USER_PROFILE_URI} from "@/util/api-host";
import {Editor, EditorContent} from "@tiptap/vue-3";
import {Document} from "@tiptap/extension-document";
import {Text} from "@tiptap/extension-text";
import {Paragraph} from "@tiptap/extension-paragraph";
import {Bold} from "@tiptap/extension-bold";
import {Italic} from "@tiptap/extension-italic";
import {Strike} from "@tiptap/extension-strike";
import {Code} from "@tiptap/extension-code";
import {HardBreak} from "@tiptap/extension-hard-break";
import {BulletList} from "@tiptap/extension-bullet-list";
import {OrderedList} from "@tiptap/extension-ordered-list";
import {ListItem} from "@tiptap/extension-list-item";
import {Link} from "@tiptap/extension-link";

export default {
  name: 'TeacherPage',
  components: {EditorContent},
  data() {
    return {
      profile: null,
      loading: false,
      error_msg: '',
      brief: null,
      about: null,
    };
  },
  mounted() {
    this.loading = true;
    this.error_msg = '';
    axios.get(
        SERVICE_USER_PROFILE_URI + '/teacher/get/public',
        {params: {teacherUsername: this.$route.params.username}},
    ).then(resp => {
      this.profile = resp.data.out;
      this.brief = new Editor({
        content: this.profile.brief,
        extensions: [Document, Text, Paragraph, Bold, Italic, Strike, Code, HardBreak, BulletList, OrderedList, ListItem, Link],
        editable: false,
      });
      this.about = new Editor({
        content: this.profile.about,
        extensions: [Document, Text, Paragraph, Bold, Italic, Strike, Code, HardBreak, BulletList, OrderedList, ListItem, Link],
        editable: false,
      });
      this.loading = false;
    }).catch(err => {
      if (err.response.data.message === 'user not found') {
        this.error_msg = 'Упс... Не нашли такого пользователя';
      } else if (err.response.data.message === 'not a teacher') {
        this.error_msg = 'У пользователя ' + this.$route.params.username + ' нет профиля преподавателя YouTeachMe :(';
      } else {
        this.error_msg = 'Упс... произошла ошибка. Попробуйте ещё раз!';
      }
      this.loading = false;
    });
  },
};
</script>

<style scoped>
.ytm-profile {
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 900px;
}

.ytm-avatar-wrapper {
  position: relative;
  width: 100%;
  padding-top: 100%;
}

.ytm-avatar {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>